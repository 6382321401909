<template>
  <b-card title="Detail Dealer">
    <b-col cols="12">
      <b-form-group
          label="Dealer Code"
          description="Dealer Code"
        >
          <b-form-input
            v-model="dealer.dealerCode"
            type="text"
            placeholder="Enter code"
            required
            disabled
          ></b-form-input>
      </b-form-group>

      

      <b-form-group
          label="Name"
          description="Dealer Name"
        >
          <b-form-input
            v-model="dealer.dealerName"
            type="text"
            placeholder="Enter name"
            required
            disabled
          ></b-form-input>
      </b-form-group>

      <b-form-group
          label="Postal"
          description="Postal"
        >
          <b-form-input
            v-model="dealer.postalCode"
            type="text"
            placeholder="Enter Postal"
            required
            disabled
          ></b-form-input>
      </b-form-group>

      <b-form-group
          label="Billing Address 1"
          description="Dealer Billing Address"
        >
          <b-form-input
            v-model="dealer.billingAddress1"
            type="text"
            placeholder="Enter Address"
            required
            disabled
          ></b-form-input>
      </b-form-group>

      <b-form-group
          label="Billing Address 2"
          description="Dealer Billing Address"
        >
          <b-form-input
            v-model="dealer.billingAddress2"
            type="text"
            placeholder="Enter Address"
            disabled
            required
          ></b-form-input>
      </b-form-group>

      <b-form-group
          label="Email Address"
          description="Dealer Email Address"
        >
          <b-form-input
            v-model="dealer.dealerEmailAddress"
            type="text"
            placeholder="Enter Address"
            required
            disabled
          ></b-form-input>
      </b-form-group>

      <b-form-group
          label="Phone"
          description="Phone"
        >
          <b-form-input
            v-model="dealer.phone"
            type="text"
            placeholder="Enter Phone"
            required
            disabled
          ></b-form-input>
      </b-form-group>

      <b-form-group
          label="Credit Exposure"
          description="Credit Exposure"
        >
          <b-form-input
            v-model="dealer.creditExposure"
            type="text"
            placeholder="Credit Exposure"
            required
            disabled
          ></b-form-input>
      </b-form-group>

      <b-form-group
          label="Total Receiveable"
          description="Total Receiveable"
        >
          <b-form-input
            v-model="dealer.totalReceivables"
            type="text"
            placeholder="Total Receiveable"
            required
            disabled
          ></b-form-input>
      </b-form-group>

      <b-form-group
          label="Sales Values"
          description="Sales Values"
        >
          <b-form-input
            v-model="dealer.salesValues"
            type="text"
            placeholder="Sales Values"
            required
            disabled
          ></b-form-input>
      </b-form-group>

      <b-form-group
          label="Overdue Amount"
          description="Overdue Amount"
        >
          <b-form-input
            v-model="dealer.overdueAmount"
            type="text"
            placeholder="Overdue Amount"
            required
            disabled
          ></b-form-input>
      </b-form-group>

      <b-form-group
          label="Overdue Days"
          description="Overdue Days"
        >
          <b-form-input
            v-model="dealer.overdueDays"
            type="text"
            placeholder="Overdue Days"
            required
            disabled
          ></b-form-input>
      </b-form-group>

      <b-form-group
          label="Industry Type"
          description="Industry Type"
        >
          <b-form-input
            v-model="dealer.industryType"
            type="text"
            placeholder="Industry Type"
            required
            disabled
          ></b-form-input>
      </b-form-group>

      <b-form-group
          label="Second Industry Type"
          description="Second Industry Type"
        >
        <v-select
          v-model="dealer.secondIndustryType"
          placeholder="----- Select Second Industry -----"
          :options="industryTypes"
          item-value="name"
          item-text="name"
        />
          
      </b-form-group>

      <b-form-group
          label="Payment Term"
          description="Payment Term"
        >
          <b-form-input
            v-model="dealer.paymentTerms"
            type="text"
            placeholder="Payment Term"
            required
            disabled
          ></b-form-input>
      </b-form-group>

      <b-form-group
          label="Status"
          description="Status"
        >
          <b-form-input
            v-model="dealerStatus"
            type="text"
            placeholder="Status"
            required
            disabled
          ></b-form-input>
      </b-form-group>
      <b-button @click="submitDealer" 
      type="submit" 
      variant="success"
      >
        Save
      </b-button>

    </b-col>
  </b-card>
</template>

<script>
import { mapActions,mapGetters } from 'vuex';
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { sleep } from '@/utils/utils';

export default {
  components:{
    "v-select":vSelect
  },
  data() {
    return {
      dealer: {
        customerName: '',
        billingAddress1: '',
        billingAddress2: '',
        dealerCode: '',
        creditLimit: '',
        creditExposure: '',
        salesValues:'',
      },
    }
  },
  mounted() {
    this.detailDealer(this.$route.params.idDealer).then((data) => {
      this.dealer = data
      this.dealer.totalReceivables =  parseFloat(data.totalReceivables.$numberDecimal);
      this.dealer.salesValues = parseFloat(data.salesValues.$numberDecimal);
      this.dealer.creditExposure = parseFloat(data.creditExposure.$numberDecimal);
      this.dealer.overdueAmount = parseFloat(data.overdueAmount.$numberDecimal);
    }).catch((e) => {

    })
    this.getIndustryTypes({active:true})
  },
  methods: {
    ...mapActions({
      detailDealer: 'dealer/detailDealer',
      getIndustryTypes: "industryType/getIndustryTypes",
      updateDealer:'dealer/updateDealer'
    }),
    submitDealer(){
      var data={
        _id:this.dealer._id,
        secondIndustryType:(this.dealer.secondIndustryType==undefined)?undefined:this.dealer.secondIndustryType.name
      }
      //console.info('dealer ',data,this.dealer._id)
      this.updateDealer(data)
      .then(async ()=>{
        this.$bvToast.toast("Successfully Edit Dealer "+this.dealer.dealerName, {
            title: "Success",
            variant: "success",
            solid: true,
          });
          await sleep(1000)
          this.$router.push({ name: "dealer" });
      })
      .catch((err)=>{
        this.$bvToast.toast(`${error.response.data.errors[0].message}`, {
          title: "Fail",
          variant: "danger",
          solid: true,
        });
      })
    }
  },
  computed: {
    ...mapGetters({
      industryTypes: "industryType/getIndustryTypes",
    }),
    dealerStatus: {
      get() {
        if (this.dealer.status !== undefined) {
          return this.dealer.status === '' ? 'Active' : 'Inactive'
        }

        return 'Inactive';
      },
      set(value) {
      }
    },
    
  }
}
</script>
